import React, { PureComponent } from "react";
import MainHeroTitle from "../MainHeroTitle";
import { black1, white } from "../styles/Colors";
import Container from "../styles/Container";
import styled from "styled-components";
import { mediaDesktop, mediaMedium } from "../styles/variables";
import Image from "../Image";
import { Eyebrows20 } from "../styles/Eyebrows";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import apiEndpoints from "../helpers/apiEndpoints";
import uniqueId from "lodash/uniqueId";
import { Linear, TimelineMax, TweenLite } from "gsap/dist/gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

const ContainerRelative = styled(Container)`
  position: relative;
  padding-top: 43px;

  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 98px;
    padding-bottom: 240px;
  }

  @media (${mediaDesktop}) {
    padding-top: 38px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  background-color: ${white};
  overflow: hidden;
  padding-bottom: 0;

  @media (${mediaMedium}) {
    padding-bottom: 0;
  }
`;

const Column = styled.div`
  position: relative;
  z-index: 2;
`;

const DisplayWrapper = styled.div`
  @media (${mediaMedium}) {
    position: absolute;
    right: -30%;
    top: 10%;
    width: 118%;
  }
`;

const Display = styled(Image)`
  margin: 0 auto;

  @media (${mediaMedium}) {
    margin: 0;
    max-width: none !important;
    max-height: none !important;
  }
`;

const Bottom = styled.div`
  background-color: ${black1};
  margin-top: -30%;
  position: relative;
  z-index: 1;

  @media (min-width: 720px) {
    margin-top: 0;
  }

  @media (${mediaMedium}) {
    position: static;
    z-index: initial;
    margin: 0;
  }
`;

const Space = styled.div`
  padding-top: 0;
  padding-bottom: 93px;

  @media (${mediaMedium}) {
    display: block;
    width: 100%;
    padding-top: 104px;
  }
`;

const SubTitle = styled(Eyebrows20)`
  margin: 0 0 28px;
`;

const Trigger = styled.div`
  position: absolute;
  bottom: 10%;

  @media (${mediaMedium}) {
    bottom: 0;
  }
`;

class AffiliateLanding extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      controllerSM: null,
      sceneSM: null,
      id: uniqueId("lading-")
    };
  }

  async componentDidMount() {
    this.ScrollMagic = await import("scrollmagic");
    TweenLite.defaultOverwrite = "none";

    ScrollMagicPluginGsap(this.ScrollMagic, TweenLite, TimelineMax);

    this.initAnimation();
  }

  componentWillUnmount() {
    const { controllerSM, sceneSM } = this.state;

    if (controllerSM) controllerSM.destroy(true);
    if (sceneSM) sceneSM.destroy(true);
  }

  initAnimation() {
    const TopToVars = {
      translateY: "-40%",
      ease: Linear.easeIn
    };

    const tweenSM = TweenLite.to(`#${this.state.id}-display`, 1, TopToVars);

    // init controller
    const controllerSM = new this.ScrollMagic.Controller();

    // build scene
    const sceneSM = new this.ScrollMagic.Scene({
      triggerElement: `#${this.state.id}-trigger-top`,
      duration: 500,
      offset: -200
    })
      .setTween(tweenSM)
      .addTo(controllerSM);

    this.setState({ controllerSM, sceneSM });
  }

  render() {
    return (
      <>
        <Wrapper>
          <ContainerRelative>
            <Trigger id={`${this.state.id}-trigger-top`} />

            <div>
              <SubTitle as="span">Affiliate</SubTitle>
              <MainHeroTitle
                link={{
                  as: "a",
                  href: `${apiEndpoints.PARTNERS_URL}/affiliates/new`,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }}
                label="Sign Up"
              >
                <OutlinedText component={Headline80} as="span">
                  NOT YOUR AVERAGE
                </OutlinedText>
                AFFILIATE PROGRAM
              </MainHeroTitle>
            </div>

            <Column id={`${this.state.id}-display`}>
              <DisplayWrapper>
                <Display fluid={this.props.image.childImageSharp.fluid} />
              </DisplayWrapper>
            </Column>
          </ContainerRelative>
          <Bottom>
            <Container>
              <Space />
            </Container>
          </Bottom>
        </Wrapper>
      </>
    );
  }
}

export default AffiliateLanding;
