import React from "react";
import HeroTitleOutline from "../HeroOutlineTitle";
import { black1, brandColor3, white } from "../styles/Colors";
import HeroDivideRight from "../HeroDivideRight";
import { graphql, useStaticQuery } from "gatsby";
import AffiliateLanding from "../publishers/AffiliateLanding";

const PublishersAffiliateStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "18-affiliate-landing.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <AffiliateLanding image={images.file} />

      <HeroTitleOutline
        theme={{ color: white, background: black1, width: "460px" }}
        title="ALL THE ACCESS"
        subtitle="WITHOUT THE SHOP"
      />

      <HeroDivideRight
        contentTag="div"
        theme={{ color: white, background: black1, lines: brandColor3 }}
      >
        <p>
          We know a branded shop isn’t right for every publisher, and we’ve got
          your back.
        </p>
        <p>
          Use affiliate links to our marketplaces in your content, and you’ll
          still get exclusive inventory, best-in-industry margins, and access to
          original commerce content.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default PublishersAffiliateStatic;
