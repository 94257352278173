import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersAffiliateStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersAffiliateDefer")
);

const Affiliate = () => (
  <Layout>
    <Seo
      title="Affiliate Commerce Solutions for Publishers"
      description="StackCommerce helps publishers grow revenue by linking to best-in-market deals through affiliate links embedded in commerce articles."
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default Affiliate;
